<template>
  <div>
    <HeaderComponent />
    <main>
      <div class="container" v-if="submissionSuccessful">
        <h1 class="thankyou_header desktop_centered">Thank you</h1>

        <div class="thankyou_text content" v-text="thankYouText"></div>

        <a :href="homepageLink" class="thank_you_return">Homepage</a>
      </div>

      <div v-else>
        <div class="top_line">
          <div class="container">
            <button class="back_link" v-on:click="returnToCalculator">
              Back
            </button>
          </div>
        </div>
        <div class="container">
          <div class="row">
            <div class="contact_page_form">
              <form action="" @submit.prevent="onSubmit">
                <h1>Make an enquiry</h1>
                <div class="content_16 contact_page_intro">
                  After you submit your details, we'll be in touch shortly to
                  check through your options.
                </div>

                <div class="form_part">
                  <div class="input_wrap">
                    <span class="label_text">PREFERRED RETAILER:</span>
                    <Multiselect id="selectedDealer"
                      :taggable="false"
                      :clearable="false"
                      :min-chars="1"
                      :searchable="true"
                      v-model="selectedDealer"
                      label="label" :options="dealers"
                      data-test-id="select-dealer"
                      data-cy='selectedDealer'
                      placeholder="Please select preferred retailer">
                    </Multiselect>

                    <span class="input_error" v-if="showErrors && !formValidationDynamic.selectedDealer">
                      Please choose dealer
                    </span>
                  </div>
                </div>

                <div class="form_part">
                  <div class="input_wrap">
                    <label for="firstName" class="label_text">First name:</label>
                    <input name="firstName" id="firstName"
                      data-cy='firstName' type="text" v-model="firstName" />
                    <span class="input_error" v-if="showErrors && !formValidationDynamic.firstName">
                      Please enter your first name
                    </span>
                  </div>
                </div>

                <div class="form_part">
                  <div class="input_wrap">
                    <label for="lastName" class="label_text">Last name:</label>
                    <input name="lastName" id="lastName" data-cy='lastName'
                      type="text" v-model="lastName" />
                    <span class="input_error" v-if="showErrors && !formValidationDynamic.lastName">
                      Please enter your last name
                    </span>
                  </div>
                </div>

                <div class="form_part">
                  <label class="input_wrap">
                    <span class="label_text">Phone:</span>
                    <MazPhoneNumberInput
                      v-model="phone"
                      color="#000"
                      no-example
                      country-code="NZ"
                      no-country-selector
                      data-cy='phone'
                    />
                    <span class="input_error" v-if="showErrors && !formValidationDynamic.phone">
                      Please enter your phone
                    </span>
                  </label>
                </div>

                <div class="form_part">
                  <div class="input_wrap">
                    <label for="email" class="label_text">Email:</label>
                    <input name="email" id="email"
                      data-cy='email' type="email" v-model="email" />
                    <span class="input_error" v-if="showErrors && !formValidationDynamic.email">
                      Please enter your email
                    </span>
                  </div>
                </div>

                <div class="controls">
                  <button v-if="formIsValid" class="enquire_button"
                    :class="{ in_progress: submissionInProgress }"
                    :disabled="submissionSuccessful">
                    Submit
                  </button>
                  <button v-else v-on:click.prevent="() => checkForm()" class="enquire_button"
                    :class="{ not_ready: true, in_progress: submissionInProgress }"
                    :disabled="submissionSuccessful">
                    Submit
                  </button>
                </div>

                <input type="hidden" value="" name="reCaptchaToken"
                  id="reCaptchaToken" class="reCaptchaToken" />

                <div class="success_message" v-if="submissionSuccessful">
                  Your enquiry submitted successfully
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
    <FooterComponent />
  </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import axios from 'axios';

import HeaderComponent from './HeaderComponent.vue';
import FooterComponent from './FooterComponent.vue';
import MazPhoneNumberInput from 'maz-ui/components/MazPhoneNumberInput';
import Multiselect from '@vueform/multiselect';
import Cleave from 'vue-cleave-component';

import commonMixin,
{ financeProducts, termOptions, frequencyOptions, mileageOptions }
    from '../mixins/commonMixin.js';
import CONSTANTS from '../data/constants';
import dealersData from '../data/dealers_data_jaguar';

export default {
    name: 'JaguarContactUs',
    components: {
        HeaderComponent,
        FooterComponent,
        MazPhoneNumberInput,
        Multiselect,
        Cleave,
    },
    mixins: [commonMixin],
    setup() {
        const route = useRoute();
        const router = useRouter();
        const dealers = ref([]);
        const selectedDealer = ref('');
        const selectedProduct = ref('');
        const selectedTerm = ref('');
        const selectedFreq = ref('');
        const selectedMileage = ref('15000');
        const isMobile = ref(false);
        const selectedModel = ref('');
        const selectedSubmodel = ref('');
        const deposit = ref(0);
        const firstName = ref('');
        const lastName = ref('');
        const phone = ref('');
        const email = ref('');
        const showErrors = ref(false);
        const submissionInProgress = ref(false);
        const submissionSuccessful = ref(false);
        const contactUrl = process.env.VUE_APP_ENQUIRY_URL;

        const onResize = () => {
            isMobile.value = window.innerWidth < CONSTANTS.MOBILE_BREAKPOINT;
        };

        const homepageLink = CONSTANTS.HOMEPAGE_LINK.JAGUAR;

        const formValidationDynamic = computed(() => {
            return {
                selectedDealer: !!selectedDealer.value,
                firstName: !!firstName.value,
                lastName: !!lastName.value,
                phone: !!phone.value,
                email: !!email.value
            };
        });

        const formIsValid = computed(() => {
            return !!(
                firstName.value &&
        lastName.value &&
        email.value &&
        phone.value &&
        selectedDealer.value.label !== ''
            );
        });

        const thankYouText = computed(() => {
            return `Thank you ${ firstName.value } for enquiring about the JAGUAR 
        ${ selectedModel.value }, ${ selectedSubmodel.value }. 
        Your preferred retailer will be in touch with you shortly.`;
        });

        const checkForm = () => {
            showErrors.value = true;

            if (formIsValid.value) {
                showErrors.value = false;
                return true;
            }
            showErrors.value = true;
            return false;
        };

        const returnToCalculator = () => {
            router.push({ name: 'jaguar-finance-calculator' });
        };

        const init = () => {
            onResize();
            window.addEventListener('resize', onResize, { passive: true });
            window.scrollTo({ top: 0, behavior: 'smooth' });

            dealers.value = dealersData.dealers.map((el) => {
                return { value: el.code, label: el.label };
            });

            if (route.query.product) {
                selectedProduct.value = financeProducts().find(
                    (el) => el.id === route.query.product
                ).id;
            } else {
                selectedProduct.value = financeProducts()[0].id;
            }

            if (route.query.term) {
                selectedTerm.value = termOptions().find(
                    (el) => {
                        return el.months.toString() === route.query.term;
                    }
                ).months.toString();
            } else {
                selectedTerm.value = termOptions()[0].months.toString();
            }

            if (route.query.deposit) {
                deposit.value = route.query.deposit;
            }

            if (route.query.frequency) {
                selectedFreq.value = frequencyOptions().find(
                    (el) => el.id === route.query.frequency
                ).id;
            } else {
                selectedFreq.value = frequencyOptions()[0].id;
            }

            selectedMileage.value = route.query.mileage
                ? route.query.mileage : '';

            selectedModel.value = route.query.model
                ? route.query.model : '';

            selectedSubmodel.value = route.query.submodel
                ? route.query.submodel : '';

            if (isMobile.value) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }

            window.passedData = {
                product: selectedProduct.value,
                term: selectedTerm.value,
                frequency: selectedFreq.value,
                mileage: selectedMileage.value,
                model: selectedModel.value,
                submodel: selectedSubmodel.value
            };
        };

        const sendEnquiry = async () => {
            if (!formIsValid.value || submissionInProgress.value) {
                return false;
            }
            submissionInProgress.value = true;
            const clientId = process.env.VUE_APP_JAGUAR_CLIENT_ID;

            const apiRequestHeaders = {
                'content-type': 'application/json',
                'x-ibm-client-id': clientId,
                // eslint-disable-next-line
        client_id: clientId,
            };

            const enquiryPayload = {
                VehicleModel: selectedModel.value,
                VehicleSubModel: selectedSubmodel.value,
                FirstName: firstName.value,
                LastName: lastName.value,
                Email: email.value,
                Phone: phone.value,
                SelectedProduct: selectedProduct.value,
                DealershipName: selectedDealer.value,
                RecaptchaToken: window.recaptchaToken,
                Deposit: deposit.value,
                SelectedTerm: selectedTerm.value,
                Frequency: selectedFreq.value,
                Mileage: selectedMileage.value,
                RRP: route.query.rrp,
                RepaymentAmount: route.query.repayments
            };
            return await axios
                .post(contactUrl, enquiryPayload, { headers: apiRequestHeaders })
                .finally(() => {
                    submissionSuccessful.value = true;
                    submissionInProgress.value = false;
                });
        };

        const onSubmit = () => {
            if (formIsValid.value) {
                sendEnquiry();
            } else {
                showErrors.value = true;
            }
        };

        onMounted(() => {
            commonMixin.runOnPageLoad();
            init();
        });

        return {
            onResize,
            dealers,
            selectedDealer,
            selectedProduct,
            selectedTerm,
            selectedFreq,
            selectedMileage,
            selectedModel,
            selectedSubmodel,
            financeProducts,
            frequencyOptions,
            termOptions,
            mileageOptions,
            formIsValid,
            checkForm,
            deposit,
            firstName,
            lastName,
            phone,
            email,
            showErrors,
            formValidationDynamic,
            submissionInProgress,
            submissionSuccessful,
            onSubmit,
            returnToCalculator,
            route,
            homepageLink,
            thankYouText
        };
    },
};
</script>
