const dealersData = {
    dealers: [
        { label: 'Archibald and Shorter Greenlane', code: 'JGASG' },
        { label: 'Archibald and Shorter Northshore', code: 'JGASN' },
        { label: 'Archibalds Motors Christchurch', code: 'JGAMC' },
        { label: 'Armstrong Prestige Dunedin', code: 'JGAPD' },
        { label: 'Armstrong Prestige Wellington', code: 'JGAPW' },
        { label: 'Bayswater European Hawkes Bay', code: 'JGBHB' },
        { label: 'Duncan & Ebbett Hamilton', code: 'JGDEH' },
        { label: 'Ebbett Prestige Tauranga', code: 'JGEPT' }
    ]
};

export default dealersData;
