
<template>
    <header>
      <div class="container">
        <img src='../../src/assets/jaguar-logo.svg' alt="Jaguar logo" class="header_logo" />
      </div>
    </header>
</template>

<script>

export default {
    name: 'HeaderComponent',
};
</script>
