// import Vue from 'vue';
// import VueRouter from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';

import JaguarContactUs from '../components/JaguarContactUs.vue';
import JaguarFinanceCalculator from '../components/JaguarFinanceCalculator.vue';

// Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: JaguarFinanceCalculator
    },
    {
        path: '/jaguar',
        name: 'jaguar-finance-calculator',
        component: JaguarFinanceCalculator
    },
    {
        path: '/jaguar/calculator',
        component: JaguarFinanceCalculator
    },
    {
        path: '/jaguar/contact-us',
        name: 'jaguar-contact-us',
        component: JaguarContactUs,
        props: true
    },
    {
        path: '/*',
        name: 'error',
        component: JaguarFinanceCalculator
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export { routes };

export default router;
