const modelImages = [
    { brand: 'Jaguar', model: 'F-Type', submodel: 'P450 75 Coupe RWD', image: 'jaguar-ftype.jpg' },
    { brand: 'Jaguar', model: 'F-Type', submodel: 'P575 AWD R75 COUPE', image: 'jaguar-ftype.jpg' },
    { brand: 'Jaguar', model: 'F-Pace', submodel: 'P250 R-DYNAMIC SE', image: 'jaguar-fpace.jpg' },
    { brand: 'Jaguar', model: 'F-Pace', submodel: 'P400E R-DYNAMIC HSE', image: 'jaguar-fpace.jpg' },
    { brand: 'Jaguar', model: 'F-Pace', submodel: '400 SPORT', image: 'jaguar-fpace.jpg' },
    { brand: 'Jaguar', model: 'F-Pace', submodel: 'SVR', image: 'jaguar-fpace.jpg' },
    { brand: 'Jaguar', model: 'I-Pace', submodel: 'R-DYNAMIC SE', image: 'jaguar-ipace.jpg' },
    { brand: 'Jaguar', model: 'I-Pace', submodel: 'R-DYNAMIC HSE', image: 'jaguar-ipace.jpg' },
    { brand: 'Jaguar', model: 'E-Pace', submodel: 'P250 R-DYNAMIC SE', image: 'jaguar-epace.jpg' },
    { brand: 'Jaguar', model: 'XE', submodel: '300 SPORT', image: 'jaguar-xe.jpg' },
    { brand: 'Jaguar', model: 'XF', submodel: '300 SPORT', image: 'jaguar-xf.jpg' }
];

const imagesData = {
    getImage1x: function (brand, model, submodel) {
        const submodelString = (typeof submodel === 'string') ? submodel : submodel.SubModel;
        if (!brand || !model || !submodel) {
            return null;
        }
        const result = modelImages.find(el => {
            let checkBrand = el.brand === brand;
            let checkModel = el.model && el.model.toLowerCase() === model.toLowerCase();
            let checkSubmodel =
                el.submodel && submodelString && el.submodel.toLowerCase() === submodelString.toLowerCase();

            return checkBrand && checkModel && checkSubmodel;
        });
        if (!result) {
            return '/img/' + (modelImages[0].image ? modelImages[0].image : null);
        }
        return '/img/' + (result.image ? result.image : null);
    },
    getImage2x: function (brand, model, submodel) {
        const submodelString = (typeof submodel === 'string') ? submodel : submodel.SubModel;
        if (!brand || !model || !submodel) {
            return null;
        }

        const result = modelImages.find(el => {
            let checkBrand = el.brand === brand;
            let checkModel = el.model && el.model.toLowerCase() === model.toLowerCase();
            let checkSubmodel =
                el.submodel && submodelString && el.submodel.toLowerCase() === submodelString.toLowerCase();

            return checkBrand && checkModel && checkSubmodel;
        });
        if (!result) {
            return '/img/' +
                (modelImages[0].image2x ?
                    modelImages[0].image2x :
                    modelImages[0].image ?
                        modelImages[0].image :
                        null);
        }
        // eslint-disable-next-line
        return '/img/' + (result.image2x ? result.image2x : result.image ? result.image : null);
    }
};

export default imagesData;
